import React, { useState } from 'react';
import { Button, TextField, Paper, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    maxWidth: 'auto',
    margin: '50px auto',
    padding: theme.spacing(3),
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px',
  },
  messagesContainer: {
    overflowY: 'auto',
    flexGrow: 1,
    marginBottom: theme.spacing(2),

  },
  user: {
    justifyContent: 'flex-end',
    paddingLeft:'0px',
  },
  bot: {
    justifyContent: 'flex-start',
    paddingLeft:'0px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  messageText: {
    fontSize: '14px',
  },
  inputText: {
    fontSize: '14px',
    padding: '12px 16px',
  },
  messagesContainerWrapper: {
    overflowY: 'auto',
    flexGrow: 1,
    maxHeight: '270px', 
  },
  
}));

function ChatComponent() {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  const [isLoading, setIsLoading] = useState(false);


  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); 
      sendMessage();
    }
  };
  
  

  const sendMessage = async () => {
    setInput('');
    setMessages([...messages, { type: 'user', text: input }]);

    setTimeout(() => {
      setIsLoading(true);
  }, 500); 


    try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${OPENAI_API_KEY}`
            },
            body: JSON.stringify({
                model: "gpt-3.5-turbo",
                messages: [
                    { role: "system", content: "Meemo is the creator of this website, you are Meemo's assistant, you are not responsible for creating and managing the website" },
                    { role: "user", content: input }
                ]
            })
        });

      const data = await response.json();
      setIsLoading(false);
        setMessages([...messages, { type: 'user', text: input }, { type: 'bot', text: data.choices[0].message.content.trim() }]);
    } catch (error) {
        setIsLoading(false);
        console.error('Error sending message:', error);
    }

    setInput('');
};
  return (
    <Paper elevation={3} className={classes.chatContainer}>
      <Typography className={classes.title}>Try ChatGPT here!</Typography>
      <div className={classes.messagesContainerWrapper}>
        <List className={classes.messagesContainer}>
          {messages.map((message, index) => (
            <ListItem key={index} className={message.type === 'user' ? classes.user : classes.bot}>
              <ListItemText primary={`${message.type === 'user' ? 'You' : 'Bot'}: ${message.text}`} />
            </ListItem>
          ))}
          {isLoading && <ListItem className={classes.bot}><ListItemText primary="Bot: Typing..." /></ListItem>}
        </List>
      </div>
      <div className={classes.inputContainer}>
        <TextField
          variant="outlined"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress} 
          InputProps={{
            classes: {
              input: classes.inputText,
            },
          }}
        />
        <Button
          color="primary"
          variant="contained"
          startIcon={<SendIcon />}
          onClick={sendMessage}
          style={{ marginLeft: '10px' }}
        >
          Send
        </Button>
      </div>
    </Paper>
  );
}

export default ChatComponent;